import {Component, Input, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {UserManagementService} from "../../service/user-management.service";
import {UserSearchInfo} from "../../model/user-search-info";
import {ActivatedRoute} from '@angular/router';
import { SharedService } from 'src/app/service/shared.service';
import { ExportService } from 'src/app/service/export-service';
import { formatDate } from '@angular/common';

export interface userIDSummary {
  checked: boolean;
  userLoginID: string;
  firstName: string;
  lastName: string;
  state: string;
  programGroup: string;
  osc: string;
  role: string;
  roleDesc: string;
  userAccntType: string;
  userEmail: string;
  createdDate: string;
  userId: string;
}

@Component({
  selector: 'app-internal-external-list',
  templateUrl: 'internal-external-list.component.html',
  styleUrls: ['./internal-external-list.compomnent.scss']
})

export class InternalExternalUserComponent {
  faDownload = faDownload;
  modalRef: BsModalRef;
  displayedColumns: string[] = ['checked', 'userLoginID', 'firstName', 'lastName', 'programGroup', 'osc', 'role', 'roleDesc', 'userAccntType', 'userEmail', 'createdDate', 'userLoginTimestamp', 'userId', 'state'];
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  userDataList = [];
  filteredUserList = [];
  loggedInUser = null;
  exportedUserListColumnsJson=[];

  constructor(private userManagementService: UserManagementService, private route: ActivatedRoute,
     private sharedService: SharedService,private exportService:ExportService) {
  }

  @Input()
  set userSearchInfo(userSearchInfo: UserSearchInfo) {
    if (userSearchInfo) {
      const obs = this.userManagementService.searchUsers(userSearchInfo);
      obs.subscribe(userList => {
        this.parseUserListResponse(userList);
      }, err => {
        console.log(`Error ${err}`);
      });
    }
  }

  parseUserListResponse(userList) {
    this.userDataList = userList.map(user => {
      const programGroupList = new Set();
      const oscSet = new Set();

      if (user.oscDetails) {
        user.oscDetails.forEach(oscDetail => {
          programGroupList.add(oscDetail.programName);
          oscSet.add(oscDetail.oscCode);
        });
      }

      const userData = {
        checked: false,
        userLoginID: user.userLoginId,
        userId: user.userId,
        firstName: user.firstName, lastName: user.lastName,
        state: user.state,
        programGroup: Array.from(programGroupList.values())?.sort().join(', '),
        osc: Array.from(oscSet.values())?.sort().join(', '),
        role: user.role?.roleName,
        roleDesc: user.role?.roleDesc,
        userAccntType: user.accountType,
        userEmail: user.emailId,
        createdDate: user.createdDate,
        userLoginTimestamp: user?.userLoginTimestamp,
        isDisabled:(user.userLoginId === this.loggedInUser.userLoginId && (this.loggedInUser.role.roleName === 'app_epms-admin_ui_aws_ets_all_prod_support' || this.loggedInUser.role.roleName === 'app_epms-admin_ui_aws_ets_all_ops_manage_user'))
      };
      return userData;
  });
    this.setData(this.userDataList);
  }

  ngOnInit() {
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.loggedInUser = user;

    this.route.queryParams.subscribe(data => {
      if (!data['editSelectedUsers']) {
        this.getAllUsersData();
      } else {
        this.filteredUserList = JSON.parse(sessionStorage.getItem('usersAssignRoleBusiness'));
        this.setData(this.filteredUserList);
      }
    });
  }
}
)
  }

  getAllUsersData() {
    this.userManagementService.getAllUsersByUserId(this.loggedInUser.userId).subscribe((userList) => {
      this.parseUserListResponse(userList);
      });
    }

  getSelectedUserId() {
    const data = this.dataSource.data.filter(d => d.checked);
    return data.length > 0 ? data : null;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setData(userDataList) {
    this.dataSource = new MatTableDataSource(userDataList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  
  addCustomSort(sort: Sort) {
    let sortedData;
    const data = this.dataSource.data.slice();
    if (!sort.active ) {
      sortedData = data;
    } else {
      sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'userLoginID':
            return this.compare((a.userLoginID || '').toLowerCase(), (b.userLoginID || '').toLowerCase(), isAsc);
          case 'firstName':
            return this.compare((a.firstName || '').toLowerCase(), (b.firstName || '').toLowerCase(), isAsc);
          case 'lastName':
            return this.compare((a.lastName || '').toLowerCase(), (b.lastName || '').toLowerCase(), isAsc);
          case 'programGroup':
            return this.compare((a.programGroup || '').toLowerCase(), (b.programGroup || '').toLowerCase(), isAsc);
          case 'osc':
            return this.compare((a.osc || '').toLowerCase(), (b.osc || '').toLowerCase(), isAsc);
          case 'role':
            return this.compare((a.role || '').toLowerCase(), (b.role || '').toLowerCase(), isAsc);
          case 'roleDesc':
            return this.compare((a.roleDesc || '').toLowerCase(), (b.roleDesc || '').toLowerCase(), isAsc);
          case 'userAccntType':
            return this.compare((a.userAccntType || '').toLowerCase(), (b.userAccntType || '').toLowerCase(), isAsc);
          case 'userEmail':
            return this.compare((a.userEmail|| '').toLowerCase(), (b.userEmail || '').toLowerCase(), isAsc);
          case 'createdDate':
            return this.compare((a.createdDate|| '').toLowerCase(), (b.createdDate || '').toLowerCase(), isAsc);
          case 'userLoginTimestamp':
            return this.compare((a.userLoginTimestamp|| '').toLowerCase(), (b.userLoginTimestamp || '').toLowerCase(), isAsc);
          case 'userId':
            return this.compare((a.userId||0), (b.userId || 0), isAsc);
          case 'state':
            return this.compare((a.state|| '').toLowerCase(), (b.state || '').toLowerCase(), isAsc);
          default:
            return 0;
        }
      });
    }

    this.dataSource = new MatTableDataSource(sortedData);
    this.dataSource.sort = sort;
    this.dataSource.paginator = this.paginator;
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  formatUserList(data:any[]){
    let index=0;
    this.exportedUserListColumnsJson=[];
    data.forEach(json=>{
      
      let expJson={
        'Select User':json['checked'],
        'User Login Id':json['userLoginID'],
        'First Name':json['firstName'],
        'Last Name':json['lastName'],
        'Program Group':json['programGroup'],
        'OSC':json['osc'],
        'Role Name':json['role'],
        'Role Desc':json['roleDesc'],
        'User Account Type':json['userAccntType'],
        'User Email Id':json['userEmail'],
        'Created Date':formatDate(json['createdDate'],this.exportService.DATE_FORMAT,this.exportService.TIMEZONE),
        'Last Login':json['userLoginTimestamp']!==null?formatDate(json['userLoginTimestamp'],this.exportService.DATE_FORMAT,this.exportService.TIMEZONE):null,
        'User ID':json['userId'],
        'Status':json['state']
        };
     
      this.exportedUserListColumnsJson.push(expJson);
      index++;
    });
  }

  exportData(exportOption) {
    const timeSpan = new Date().toISOString();
    this.formatUserList(this.dataSource.data);
    switch (exportOption) {
      case 'xls':
        this.exportService.exportFile('xlsx', `UserList-${timeSpan}`, 'UserList',this.exportedUserListColumnsJson);
        break;
      case 'csv':
        this.exportService.exportFile('csv', `UserList-${timeSpan}`, 'UserList',this.exportedUserListColumnsJson);
        break;
    }
  }

  filterTableList(event, selectedUserData) {
    if (event.checked && this.filteredUserList.length === 0) {
      this.filteredUserList = this.userDataList.filter(userListData => userListData.osc === selectedUserData.osc && userListData.programGroup === selectedUserData.programGroup && userListData.role === selectedUserData.role)
      this.setData(this.filteredUserList);
    } else if (!event.checked && this.filteredUserList.filter(item => item.checked === true).length === 0) {
      this.filteredUserList = [];
      if (this.userDataList?.length > 0) {
        this.setData(this.userDataList);
      } else {
        this.getAllUsersData();
      }
    }
  }
}

